<template>
    <v-container>
        <v-row align="center">
            <v-col>
                <v-text-field v-model="searchText" placeholder="搜尋醫師姓名" @keydown.enter="search"></v-text-field>
            </v-col>
            <v-col cols="auto" @click="search">
                <v-btn :loading="loading">搜尋</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="data.length">
            <v-col>
                <v-simple-table fixed-header height="60vh" style="white-space: nowrap">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th style="left: 0; background-color: #fff; z-index: 99" class="text-left">
                                    查看
                                </th>
                                <th style="left: 56px; background-color: #fff; z-index: 99" class="text-left">
                                    姓名
                                </th>
                                <th class="text-left">HCO</th>
                                <th class="text-left">綁定LINE</th>
                                <th class="text-left">綁定iFun</th>
                                <th class="text-left">Digital_Consent</th>
                                <th class="text-left">FMI_ICF</th>
                                <th class="text-left">Specialty</th>
                                <th class="text-left">Product</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data" :key="item.label">
                                <td class="column-fixed-td">
                                    <v-icon @click="handleGenerate(item.id)">
                                        {{ icon }}</v-icon>
                                </td>
                                <td class="column-fixed-td" style="left: 56px">
                                    {{ item.name }}
                                </td>
                                <td>
                                    {{
                                        (item.hco[0] &&
                                            item.hco[0].hco_id.name) ||
                                        '-'
                                    }}
                                </td>
                                <td>{{ item.profile_id ? '是' : '否' }}</td>
                                <td>{{ item.is_ifunction_platform_id_exist ? '是' : '否' }}</td>
                                <td>
                                    {{
                                        item.is_digital_consent == 1
                                        ? '已簽署'
                                        : '尚未簽署'
                                    }}
                                </td>
                                <td>
                                    {{
                                        item.is_fmi_consent == 1
                                        ? '已簽署'
                                        : '尚未簽署'
                                    }}
                                </td>
                                <td>
                                    {{
                                        (item.specialty[0] &&
                                            item.specialty[0].specialty_id
                                                .name) ||
                                        '-'
                                    }}
                                </td>
                                <td>
                                    {{
                                        (item.product_disease_ta[0] &&
                                            item.product_disease_ta[0]
                                                .product_disease_ta_id
                                                .indication) ||
                                        '-'
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import API from '@/models/api';
import { mdiCardAccountDetailsOutline } from '@mdi/js';
export default {
    data() {
        return {
            searchText: '',
            data: [],
            loading: false,
            icon: mdiCardAccountDetailsOutline
        };
    },
    methods: {
        async search() {
            if (!this.searchText) {
                this.data = [];
                return;
            }
            this.loading = true;
            try {
                const res = await API.Hcp.search(this.searchText);
                this.data = res.data;
                this.loading = false;
            } catch (e) {
                console.log(e.response);
                this.loading = false;
            }
        },
        handleGenerate(id) {
            this.$router.push({ path: '/qrcode', query: { id: id } });
        }
    }
};
</script>


<style lang="scss">
.v-application--wrap {
    min-height: 100% !important;
}

.column-fixed-td {
    position: sticky;
    left: 0;
    background-color: #fff;
}
</style>
